import { Link } from "gatsby"
import styles from "./Kampanj.module.css"
import { Order } from "../formPage/FormPage"
import React from "react"

const formatThousands = (num: number) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

const KampanjMoped: React.FC<{
  firstStart?: string
  priceNum?: number
  data: any
}> = ({ priceNum = 6_200, data }) => {
  const price = `${formatThousands(priceNum)}kr`
  const campaignPrice = `${formatThousands(4990)}kr`
  return (
    <section className={styles.Kampanj}>
      <div>
        <h3 className={styles.title}>Paket Moped klass 1 – AM</h3>
        <div className={styles.mainRow}>
          <div>
            <ul className="mx-2">
              <li>Teorikurs 8 timmar</li>
              <li>4 praktisk timmar körning på moped</li>
              <li>Teoripaket</li>
              <li>Syntest</li>
            </ul>
            <div className={styles.column}>
              <span className={styles.price}>
                {campaignPrice}{" "}
                <span style={{ fontSize: "14px" }}>ordinarie pris {price}</span>
              </span>
            </div>
          </div>
          <div className="md:ml-3">
            <img src={require("./../../images/moped.jpg")} />
          </div>
        </div>
        <h2>Kommande kurser</h2>
        {data.map(row => (
          <div
            className="flex gap-4 py-1 px-1 whitespace-normal sm:whitespace-pre"
            key={row.datum[0]?.datum + "_moped"}
          >
            <div className="flex-1">Datum: {row.datum[0]?.text}</div>
            <div className="flex-1">Tid: {row.tid[0]?.text}</div>
            <div className="flex-1">Pris(kr): {row.pris[0]?.text}</div>
            <div className="flex-grow">{row.lediga_platser} lediga platser</div>
            {row.lediga_platser && row.lediga_platser > 0 ? (
              <Link
                to={"/formular"}
                state={{
                  orderState: {
                    items: [
                      {
                        name:
                          "Mopedkurs Göteborg " +
                          row.datum[0]?.text +
                          ", " +
                          row.tid[0]?.text,
                        label: "Moped klass 1 - AM",
                        price: row.pris[0]?.text,
                      },
                    ],
                  },
                  orderType: Order.MOPED,
                }}
              >
                <div>Boka</div>
              </Link>
            ) : (
              <div>Fullbokat!</div>
            )}
          </div>
        ))}
      </div>
    </section>
  )
}

export default KampanjMoped
