import { Link } from "gatsby"
import { StandardButton } from "../atoms/Button"
import styles from "./Kampanj.module.css"
import { Order } from "../formPage/FormPage"

const formatThousands = (num: number) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

const KampanjMC: React.FC<{
  firstStart?: string
  priceNum?: number
}> = ({ priceNum = 26_500 }) => {
  const price = `${formatThousands(priceNum)}kr`
  return (
    <section className={styles.Kampanj}>
      <div>
        <h3 className={styles.title}>
          Intensiv MC-veckan i Göteborg
          <br /> 1/4 - 31/10
        </h3>
        <h6>En veckas intensivkurs (All Inclusive)</h6>
        <div className={styles.mainRow}>
          <div>
            <ul>
              <li>14 körlektioner (55min)</li>
              <li>Riskettan</li>
              <li>Risktvåan</li>
              <li>Bokpaket på svenska</li>
              <li>Onlinetester obegränsat</li>
              <li>Banavgift</li>
              <li>Administrationsavgift</li>
              <li>Kräver inga förkunskaper (men körkortstillstånd)</li>
              <li>
                Kräver egna skyddskläder och egen hjälm (på grund av pandemin)
              </li>
              <li>Tillkommer avgift för Trafikverkets teori och körprov</li>
            </ul>
          </div>
          <img src={require("./../../images/mc3.jpg")} />
        </div>

        <div className={styles.priceRow}>
          {/*          <div className={styles.column}>
            <p>Nästa start 1/6 1/8 1/10</p>

          </div>*/}
          <div className={styles.column}>
            <span className={styles.price}>18 990:- </span>
            <span>Ord pris: 20 460:-</span>
            <p>
              <strong>Rabatt just nu: {20460 - 18990}:-</strong>
            </p>
          </div>
        </div>
        <Link
          to={"/formular"}
          state={{
            orderState: {
              items: [
                {
                  name: "Intensivurs MC Göteborg",
                  label: "1 veckas Intensivkurs, MC",
                  price: priceNum,
                },
              ],
            },
            orderType: Order.COURSE,
          }}
        >
          <div css={StandardButton}>Boka nu direkt!</div>
        </Link>
      </div>
    </section>
  )
}

export default KampanjMC
