import React from "react"
import { Package } from "./entities/package"

const transformSourceDataToPackage = source => {
  const data = source.node.dataRaw
  return {
    title: data.package_title[0].text,
    includes: data.includes.map(include => include?.product[0].text),
    price: data.productprice,
    oldPrice: data.previous_price,
  }
}

const PaketMC = ({ data, addItem }) => {
  const BRONS = transformSourceDataToPackage(data.edges[3])
  const SILVER = transformSourceDataToPackage(data.edges[4])
  const GULD = transformSourceDataToPackage(data.edges[5])
  return (
    <div
      className="flex flex-col md:flex-row"
      style={{
        margin: "0",
        color: "#121212",
        textAlign: "left",
      }}
    >
      <Package
        data={BRONS}
        image={require("./../images/mcBRONZE.png")}
        onClick={() =>
          addItem({
            title: "Brons-paket MC",
            sub: "Körpaket Brons",
            price: BRONS.price,
          })
        }
      />
      <Package
        data={SILVER}
        image={require("./../images/mcSILVER.png")}
        onClick={() =>
          addItem({
            title: "Silver-paket MC",
            sub: "Körpaket Silver",
            price: SILVER.price,
          })
        }
      />
      <Package
        data={GULD}
        image={require("./../images/mcGOLD.png")}
        onClick={() =>
          addItem({
            title: "Guld-paket MC",
            sub: "Körpaket Guld",
            price: GULD.price,
          })
        }
      />
    </div>
  )
}

export default PaketMC
