import React from "react"
import { Package } from "./entities/package"

const transformSourceDataToPackage = source => {
  const data = source.node.dataRaw
  return {
    title: data.package_title[0].text,
    includes: data.includes.map(include => include?.product[0].text),
    price: data.productprice,
    oldPrice: data.previous_price,
  }
}
const Paket = ({ data, addItem }) => {
  const BRONS = transformSourceDataToPackage(data.edges[0])
  const SILVER = transformSourceDataToPackage(data.edges[1])
  const GULD = transformSourceDataToPackage(data.edges[2])
  return (
    <div
      className="flex flex-col md:flex-row"
      style={{
        margin: "0",
        color: "#121212",
        textAlign: "left",
      }}
    >
      <Package
        data={BRONS}
        image={require("./../images/carBronze2.png")}
        onClick={() =>
          addItem({
            title: "Brons-paket",
            sub: "Körpaket Brons",
            price: BRONS.price,
          })
        }
      />
      <Package
        data={SILVER}
        image={require("./../images/carSilver2.png")}
        onClick={() =>
          addItem({
            title: "Silver-paket",
            sub: "Körpaket Silver",
            price: SILVER.price,
          })
        }
      />
      <Package
        data={GULD}
        image={require("./../images/carGold2.png")}
        onClick={() =>
          addItem({
            title: "Guld-paket",
            sub: "Körpaket Guld",
            price: GULD.price,
          })
        }
      />
    </div>
  )
}

export default Paket
