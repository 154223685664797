import { Link } from "gatsby"
import React from "react"
import { StandardButton } from "../atoms/Button"
import styles from "./Kampanj.module.css"
import { Order } from "../formPage/FormPage"

const formatThousands = (num: number) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

const Kampanj: React.FC<{ firstStart?: string; priceNum?: number }> = ({
  firstStart = "",
  priceNum = 26_500,
}) => {
  const price = `${formatThousands(priceNum)}kr`

  return (
    <section className={styles.Kampanj}>
      <div>
        <h3 className={styles.title}>Intensiv B-körkort i Göteborg</h3>
        <h6>2 veckors intensivkurs (All Inclusive)</h6>
        <div className={styles.mainRow}>
          <div>
            <ul>
              <li>40 körlektioner</li>
              <li>Riskettan och risktvåan</li>
              <li>Bokpaket på svenska</li>
              <li>Onlinetester obegränsat</li>
              <li>Bokning av teori och körprov</li>
              <li>Lån av bilen vid körprovet</li>
              <li>Administrationsavgift inkluderad</li>
              <li>Allt sker med samma lärare</li>
              <li>Kräver inga förkunskaper (men körkortstillstånd)</li>
              <li>Tillkommer avgift för Trafikverkets teori och körprov</li>
            </ul>
            <i>
              <strong>*Gäller automat körkort</strong>
            </i>
          </div>
          <img src={require("./../../images/car.png")} />
        </div>

        <div className={styles.priceRow}>
          <div className={styles.column}>
            <p>Start enligt överenskommelse,</p>
            <p>Vi anpassar efter ert önskemål.</p>
          </div>
          <div className={styles.column}>
            <span className={styles.price}>27995:- </span>
            <span className={styles.priceOrd}>(ord. 29350:-) </span>
          </div>
        </div>
        <Link
          to={"/formular"}
          state={{
            orderState: {
              items: [
                {
                  name: "Intensiv B-körkort Göteborg",
                  label: "2 veckors Intensivkurs, automat",
                  price: priceNum,
                },
              ],
            },
            orderType: Order.COURSE,
            car: true,
          }}
        >
          <div css={StandardButton}>Boka nu direkt!</div>
        </Link>
      </div>
    </section>
  )
}

export default Kampanj
